import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import GraphQLErrorList from "components/graphql-error-list";
import SEO from "components/seo";
import Layout from "containers/layout"
import TextForm from "modules/TextForm/TextForm";
import ContactForm from "modules/TextForm/ContactForm";
import { LineHeader, Title } from "components/Module/Module";
import { SiteContext } from "context/site-context";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";


export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    contact: sanityContact {
      contact {
        _rawContent
      }
    }
  }
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;
`;

const ContactPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  console.log(data.contact)
  const contactNode = (data || {}).contact
    ? data.contact.contact._rawContent
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout
      pageElements={
        <CustomLineHeader nomargin>
          <Title>CONTACT US</Title>
        </CustomLineHeader>
      }
    >
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <TextForm content={contactNode}>
        <ContactForm />
      </TextForm>
    </Layout>
  );
};

export default ContactPage;
