import React from "react";
import styled from "styled-components";
import { Input, Select, SubmitButton } from "components/Form/Form";
import PortableText from "components/portableText";
import { SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";
import Grid from "components/Grid/Grid";

const Section = styled(SectionMargin)`
  //margin-bottom: 100px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ModuleInput = styled(Input)`
  ${type("subheaderC")}

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const ModuleSelect = styled(Select)`
  ${type("subheaderC")}

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const ModuleText = styled.p`
  ${fontstack.primary}
  ${type("titleB")}
  color: var(--brand-primary);
  margin: 0;

  ${media.large` 
    ${type("headlineB")}
  `}
`;

const ModuleTextCol = styled.div`
  grid-column: 1 / span 6;

  ${media.large` 
    grid-column: 2 / span 4;
  `}
`;

const ModuleFormCol = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  align-items: center;

  ${media.large` 
    grid-column: 7 / span 5;
  `}
`;

const TextForm = ({content, children}) => {
  
  return (
    <Section small>
      <Grid>
        <ModuleTextCol>
          <ModuleText>
            {content && <PortableText blocks={content} />}
          </ModuleText>
        </ModuleTextCol>
        <ModuleFormCol>{children}</ModuleFormCol>
      </Grid>
    </Section>
  );
};

export default TextForm;

export const TextInput = React.forwardRef(({ ...rest }, ref) => {
  return (
    <InputWrapper>
      <ModuleInput ref={ref} {...rest} />
    </InputWrapper>
  );
});

export const SelectInput = React.forwardRef(({ ...rest }, ref) => {
  return (
    <InputWrapper>
      <ModuleSelect ref={ref} {...rest} />
    </InputWrapper>
  );
});
